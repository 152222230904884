<template>
  <v-container fill-height fluid>
    <v-layout align-center class="text-center" justify-center>
      <v-flex md4 sm8 xs12>
        <v-avatar color="indigo">
          <v-icon dark>
            mdi-link
          </v-icon>
        </v-avatar>
        <p>
          <span class="text-h5">Flooring Calculator Link Generator</span>
          <br>
          <small>Tyrrells of Burnbank - Home Furnishings</small>
        </p>
        <v-form>
          <v-text-field
            v-model="packSize"
            label="Square metres per pack"
            outline/>
        </v-form>
        <v-btn
          color="primary"
          @click="copyLink"
          v-if="inputValid">
          Copy code to clipboard
        </v-btn>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      timeout="4000">
      <span v-if="copySuccess">
        Code snippet copied to clipboard for <b>{{ packSize }}</b> square metre pack size.
      </span>
      <span v-else>
        Unable to copy code snippet to clipboard.
      </span>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'LinkGenerator',
  data() {
    return {
      packSize: null,
      copySuccess: null,
      snackbar: false,
    };
  },
  methods: {
    copyLink() {
      const url = window.location.origin + window.location.pathname
        + this.$router.resolve({ name: 'packs', params: { pack_size: this.packSize } }).href;
      const snippet = `<p>This pack covers ${this.packSize} square metres. <a href="${url}" onclick="window.open(this.href,'targetWindow','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=450,height=600'); return false;">Click here</a> to calculate the number of packs you will require for your floor size.</p>`;
      navigator.clipboard.writeText(snippet).then(() => {
        this.copySuccess = true;
        this.snackbar = true;
      }, () => {
        this.copySuccess = false;
        this.snackbar = true;
      });
    },
  },
  computed: {
    inputValid() {
      return this.packSize !== null
        && this.packSize > 0;
    },
  },
};
</script>
