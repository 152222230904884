import Vue from 'vue';
import VueRouter from 'vue-router';
import Calculator from '@/components/Calculator.vue';
import LinkGenerator from '@/components/LinkGenerator.vue';

Vue.use(VueRouter);

const routes = [
  {
    name: 'packs',
    path: '/packs/:pack_size',
    component: Calculator,
  },
  {
    name: 'link',
    path: '/link',
    component: LinkGenerator,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
